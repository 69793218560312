































import { Component, Model, Vue, Prop } from 'vue-property-decorator';
import {Dict} from "@/modules/budget/staffing-table/types";
import { Values } from 'vue-i18n';
import EduSubjectSelection from "@/modules/budget/staffing-table/education/EduSubjectSelection.vue";
import {staffTabAccess} from "@/modules/budget/staffing-table/common";


const modelChangeEvent = 'change';

@Component({
  components: {EduSubjectSelection}
})
export default class EduSubjectCell extends Vue {

  // region Модель, свойства
  @Model(modelChangeEvent, {
    type: Array,
    default: null,
    required: false,
  })
  public readonly value!: string[] | null;

  @Prop({
    type: String,
    required: false,
    default: null
  })
  public readonly orgTypeCode!: string | null

  @Prop({
    type: Map,
    required: true,
  })
  public readonly eduSubjMap!: Map<string, Dict.StEduSubject>;


  // region Lifecycle
  // noinspection JSUnusedLocalSymbols
  private created() {
    if (this.value === null) {
      this.selectedEduSubjects = [];
    } else {
      this.selectedEduSubjects = [...this.value];
    }

    this.$watch('modalVisible', () => {
      if (this.modalVisible) {
        if (this.value === null) {
          this.selectedEduSubjects = [];
        } else {
          this.selectedEduSubjects = [...this.value];
        }
      }
    });
  }

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

  // region Утилиты
  private getTranslate(key: string, values?: Values): string {
    return String(this.$t(`modules.budget.staffing_table.*EduSubjectCell*.${key}`, values));
  }

    private getEduSubjectText(subject: string[]): string {
        let result: string;

        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            result = subject.map((s) => this.eduSubjMap.get(s)?.nameKk ?? '').join('; ');
        } else {
            result = subject.map((s) => this.eduSubjMap.get(s)?.nameRu ?? '').join('; ');
        }

        return result;
    }
    // endregion


  // region Основное
  private selectedEduSubjects: string[] = [];

  // region Модальное окно
  private modalVisible = false;

  private onModalCancelled() {
    if (this.value === null) {
      this.selectedEduSubjects = [];
    } else {
      this.selectedEduSubjects = [...this.value];
    }
  }

  private onModalApplied() {
      this.$emit(modelChangeEvent, this.selectedEduSubjects);
  }
  // endregion
}

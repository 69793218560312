




















































































































































































































































































































































































































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import i18nDb from '@/services/i18n-db';
import {Ax} from '@/utils';
import {AddAttrField, ListItem, ListItemPart, Overlay, PaginationPages} from '../components';
import I18n from '../I18n';
import {
    eadDropdownValueMap,
    eadToValueListMap,
    getEadType,
    getFromStafftabAddAttrHolder,
    getValueFromDropdown,
    prepareUrl,
    salaryForms,
    selectAllOnClick,
    store,
    setToStafftabAddAttrHolder,
    stafftabAddAttrHolderHasDifference,
    updateFormattedDate, staffTabAccess,
} from '../common';
import GaDepartmentSelection from '../departments/GaDepartmentSelection.vue';
import {
    BudgetVariants,
    Comp,
    Department,
    Dict,
    Ead,
    Edu,
    Employee,
    Org,
    Position,
    Report,
    Utils,
    Version,
    employeePositionMode
} from '../types';
import DepartmentPositionCell from '../department-positions/DepartmentPositionCell.vue';
import EducationLevelCell from '../education/EduLevelCell.vue';
import {SingleOrgField} from '../organizations';
import {SingleVersionField} from '../versions';
import DossierTextView from './DossierTextView.vue';
import EduSubjectCell from "@/modules/budget/staffing-table/education/EduSubjectCell.vue";
import axios from "axios";
import {codes} from "@/modules/budget/staffing-table/old-v1-reports/subprogram-dist/controllers-common";


/** Ключи переводов ошибок для полей */
interface IRowErrors {
    /** Ключ ошибки поля "Дата формирования" */
    creationDate: string | null;

    /** Ключ ошибки поля "Дата приема на государственную службу" */
    admissionDate: string | null;

    /** Ключ ошибки поля "Дата вступления в должность" */
    takingOfficeDate: string | null;

    /** Ключ ошибки поля "Дата начала работы" */
    workStartDate: string | null

    /** Ключ ошибки поля "Регион" */
    region: string | null;

    /** Ключ ошибки поля "Ставка" */
    pos: string | null;

    /** Ключ ошибки поля "Должность в подразделении" */
    timeRate: string | null;

    /** Ключ ошибки поля "Должность в подразделении" */
    fullName: string | null;

    /** Ключ ошибки поля Дата решения комиссии по стажу */
    cdDate: string | null;

    /** Ключ ошибки поля Количество лет по решению комиссии */
    cdYears: string | null;

    /** Ключ ошибки поля Количество месяцев по решению комиссии */
    cdMonths: string | null;

    /** Ключ ошибки поля Количество дней по решению комиссии */
    cdDays: string | null;

    /** Ключ ошибки поля Вакансия: Количество лет-мес */
    experienceVacancy: string | null;

    /** Ключ ошибки поля "Уровень образования" */
    eduLevel: string | null

    /** Ключ ошибки поля "Предмет" */
    subjects: string | null

    /** Ключ ошибки доп. атрибутов (Key-код доg.атрибута, Value-текст ошибки*/
    eadErrorMap: Map<string, string>;

    /** Ключ ошибки фактической нагрузки сотрудников образования (Key-id настройки, Value-текст ошибки */
    wldErrorMap: Map<number, string>;
}

/**
 * Строка таблицы
 */
interface IRow extends Comp.TableRow<Employee> {
    /**
     * Ключи переводов ошибок для полей
     */
    errors: IRowErrors;

    eadMap: Map<Ead.EadC, Ead.EadD>;
    originalEadMap: Map<Ead.EadC, Ead.EadD>;

    wldMap: Map<Edu.WlC, Edu.WlD>;
    originalWldMap: Map<Edu.WlC, Edu.WlD>;

  /**
   *  количесво нормативных часов
   */
    activeHours: number | null
}

/** Изменение данных */
interface IChanges {
    /** Дата приема на государственную службу */
    admissionDate?: Date | null;

    /** Дата формирования */
    creationDate?: string;

    /** ФИО */
    fullName?: string;

    /** Должность в подразделении */
    pos?: Position;

    /**
     * Ставка
     *
     * Например, если сотрудник работает на поставки, в поле должно быть число `0.5`
     *
     * Если не заполнено, считается равным `1`
     */
    timeRate?: number | null | string;

    /** Дата вступления в должность */
    takingOfficeDate?: string;

    /** Дата решения комиссии по стажу (для уточнения стажа) */
    cdDate?: Date | null;

    /** Количество лет по решению комиссии (для уточнения стажа) */
    cdYears?: number | null | string;

    /** Количество месяцев по решению комиссии (для уточнения стажа) */
    cdMonths?: number | null | string;

    /** Количество дней по решению комиссии (для уточнения стажа) */
    cdDays?: number | null | string;

    /** Признак "является вакансией" */
    vacancy?: boolean | null;

    /** Признак "является вакансией с указанием стажа (количество лет-мес) */
    experienceVacancy?: number | null;

    /** Дата начала работы */
    workStartDate?: Date | null;

    /** Дата окончания работы (дата увольнения) */
    workEndDate?: Date | null;

    /**
     * Премирование
     *
     * Пустое значение принимается равным "false"
     */
    bonus?: boolean | null;

    /**
     * Уровень образования
     */
    eduLevel?: Dict.DictEducationLevel | null

    /**
     * Список предметов образования
     */
    eduSubjects?: string[] | null

    /** Признак "Пенсионер" */
    retiree?: boolean;

    /** Форма, по которой будет рассчитываться зарплата сотрудника */
    form?: Report.FormKey | null;

    /** Бюджетная программа, назначенная сотруднику */
    budgetProgram?: number | null;

    /**
     * Образование, дополнительная бюджетная программа
     */
    eduAdditionalBudgetProgram?: number | null;
}

const ALL_ITEMS = 900719925;


// region Утилиты
const emptyErrors = (): IRowErrors => {
    return {
        creationDate: null,
        admissionDate: null,
        takingOfficeDate: null,
        workStartDate: null,
        region: null,
        pos: null,
        timeRate: null,
        fullName: null,
        cdDate: null,
        cdYears: null,
        cdMonths: null,
        cdDays: null,
        experienceVacancy: null,
        eduLevel: null,
        subjects: null,
        eadErrorMap: new Map<string, string>(),
        wldErrorMap: new Map<number, string>(),
    };
};

const copyEadData = (source: Ead.EadD): Ead.EadD => {
    return {
        id: source.id,
        emp: source.emp,
        conf: source.conf,
        valueBool: source.valueBool,
        valueText: source.valueText,
        valueNumber: source.valueNumber,
        valueSalaryRate: source.valueSalaryRate,
        valueNormRate: source.valueNormRate,
        valueDate: source.valueDate,
        valueMilitaryRank: source.valueMilitaryRank,
        valueMilitaryTitle: source.valueMilitaryTitle,
        calcHint: source.calcHint,
        factHours: source.factHours,
        startPeriod: source.startPeriod,
        endPeriod: source.endPeriod
    };
};

const copyEadDataList = (source: Array<Ead.EadD>): Array<Ead.EadD> => {
    const result: Array<Ead.EadD> = [];
    source.forEach(sourceItem => {
        result.push(copyEadData(sourceItem));
    });
    return result;
};

const resetIdsAndEmpsToNull= (source: Array<Edu.WlD> | null): void => {
    if (source) {
        source.forEach(sourceItem => {
            sourceItem.id = null;
            sourceItem.emp = null;
        });
    }
};

const copyData = (source: Employee): Employee => {
    const eadList: Array<Ead.EadD> | null = (source.eadList === null ? null : copyEadDataList(source.eadList));

    return {
        id: source.id,
        creationDate: source.creationDate,
        admissionDate: source.admissionDate,
        takingOfficeDate: source.takingOfficeDate,
        fullName: source.fullName,
        region: source.region,
        pos: source.pos,
        retiree: source.retiree,
        timeRate: source.timeRate,
        cdDate: source.cdDate,
        cdYears: source.cdYears,
        cdMonths: source.cdMonths,
        cdDays: source.cdDays,
        vacancy: source.vacancy,
        experienceVacancy: source.experienceVacancy,
        department: source.department,
        workStartDate: source.workStartDate,
        workEndDate: source.workEndDate,
        serialNumber: source.serialNumber,
        bonus: source.bonus,
        eduLevel: source.eduLevel,
        eduSubjects: source.eduSubjects,
        eadList,
        eduWlList: source.eduWlList,
        form: source.form,
        budgetProgram: source.budgetProgram,
        orgType: source.orgType,
        eduAdditionalBudgetProgram: source.eduAdditionalBudgetProgram,
    };
};

const hasDifferenceEduSubjects = (subjects: string[], subjects2: string[]): boolean => {
    if (subjects.length !== subjects2.length) {
        return true;
    }
    for (const item of subjects) {
        if (!subjects2.includes(item)) {
            return true;
        }
    }
    return false;
}

const hasDifference = (row: IRow): boolean => {
    const original = row.original;
    if (original === null) {
        return true;
    }

    const data = row.data;
    if (
        (data.creationDate !== original.creationDate)
        || (data.admissionDate !== original.admissionDate)
        || (data.takingOfficeDate !== original.takingOfficeDate)
        || (data.fullName !== original.fullName)
        || (data.retiree !== original.retiree)
        || (data.timeRate !== original.timeRate)
        || (data.cdDate !== original.cdDate)
        || (data.cdYears !== original.cdYears)
        || (data.cdMonths !== original.cdMonths)
        || (data.cdDays !== original.cdDays)
        || (data.vacancy !== original.vacancy)
        || (data.experienceVacancy !== original.experienceVacancy)
        || (data.region?.id !== original.region?.id)
        || (data.pos?.id !== original.pos?.id)
        || (data.form !== original.form)
        || (data.budgetProgram !== original.budgetProgram)
        || (data.workStartDate !== original.workStartDate)
        || (data.workEndDate !== original.workEndDate)
        || (data.bonus !== original.bonus)
        || (data.eduLevel !== original.eduLevel)
        || (data.eduAdditionalBudgetProgram !== original.eduAdditionalBudgetProgram)
        || (data.eduSubjects?.length !== original.eduSubjects?.length)
        || (hasDifferenceEduSubjects(data.eduSubjects ?? [], original.eduSubjects ?? []))

    ) {
        return true;
    }

    if (row.eadMap.size !== row.originalEadMap.size) {
        return true;
    }

    for (const eadConf of row.eadMap.keys()) {
        const eadData = row.eadMap.get(eadConf);
        // noinspection JSIncompatibleTypesComparison
        if (eadData === undefined) {
            return true;
        }

        const originalEadData = row.originalEadMap.get(eadConf);
        // noinspection JSIncompatibleTypesComparison
        if (originalEadData === undefined) {
            return true;
        }

        if (eadData.factHours !== originalEadData.factHours) {
            return true;
        }

        if (eadData.startPeriod !== originalEadData.startPeriod){
            return true
        }

        if (eadData.endPeriod !== originalEadData.endPeriod){
            return true
        }

        const type = getEadType(eadConf.ead);

        if (stafftabAddAttrHolderHasDifference(type, eadData, originalEadData)) {
            return true;
        }

        if (eadData.valueMilitaryRank?.id !== originalEadData.valueMilitaryRank?.id) {
            return true;
        }
        if (eadData.valueMilitaryTitle?.id !== originalEadData.valueMilitaryTitle?.id) {
            return true;
        }
    }

    for (const [conf, wldData] of row.wldMap.entries()) {
        // noinspection JSIncompatibleTypesComparison
        if (wldData === undefined) {
            return true;
        }
        const originalWldData = row.originalWldMap.get(conf);
        // noinspection JSIncompatibleTypesComparison
        if (originalWldData === undefined) {
            return true;
        }

        if (wldData.value !== originalWldData.value){
            return true
        }
    }


    return false;
};

const emptyEadData = (): Ead.EadD => {
    return {
        id: null,
        emp: null,
        conf: null,
        valueBool: null,
        valueText: null,
        valueNumber: null,
        valueSalaryRate: null,
        valueNormRate: null,
        valueDate: null,
        valueMilitaryRank: null,
        valueMilitaryTitle: null,
        calcHint: null,
        factHours: null,
        startPeriod: null,
        endPeriod: null
    };
};

const copyAddAttrFromDataToRow = (eadCList: Array<Ead.EadC>, row: IRow) => {
    row.eadMap.clear();
    row.originalEadMap.clear();
    eadCList.forEach(conf => {
        const data = emptyEadData();
        row.eadMap.set(conf, data);
        row.originalEadMap.set(conf, copyEadData(data));
    });

    if (row.data.eadList !== null) {
        row.data.eadList.forEach(data => {
            const conf = data.conf;
            if (conf !== null) {
                row.eadMap.set(conf, data);
            }
        });
    }

    if (row.original !== null) {
        if (row.original.eadList !== null) {
            row.original.eadList.forEach(data => {
                const conf = data.conf;
                if (conf !== null) {
                    row.originalEadMap.set(conf, data);
                }
            });
        }
    }
};

const copyAddAttrFromRowToData = (row: IRow) => {
    const eadList: Array<Ead.EadD> = [];
    const wldList: Array<Edu.WlD> = [];
    row.data.eadList = eadList;
    row.data.eduWlList = wldList;
    for (const conf of row.eadMap.keys()) {
        const data = row.eadMap.get(conf);
        // noinspection JSIncompatibleTypesComparison
        if (data !== undefined) {
            eadList.push(data);
            data.conf = conf;
        }
    }

    for (const [wlc, wldData] of row.wldMap.entries()) {
        if (wldData.value !== null) {
            wldList.push(wldData)
        }
    }
};
// endregion

const emptyWldData = (conf: Edu.WlC): Edu.WlD => {
    return {
        id: null,
        value: null,
        emp: null,
        conf: conf,
    };
};

const copyWldData = (source: Edu.WlD): Edu.WlD => {
    return {
        id: source.id,
        value: source.value,
        emp: source.emp,
        conf: source.conf,
    };
};

const copyEduFromDataToRow = (wldList: Array<Edu.WlC>, row: IRow) => {
    row.wldMap.clear();
    row.originalWldMap.clear();
    wldList.forEach(conf => {
        const data = emptyWldData(conf);
        row.wldMap.set(conf, data);
        row.originalWldMap.set(conf, copyWldData(data));
    });
    if (row.data.eduWlList !== null) {
        row.data.eduWlList.forEach(wldDate => {
            if (wldDate.conf !== null) {
                row.wldMap.set(wldDate.conf, wldDate);
            }
        });
    }

    if (row.original !== null) {
        if (row.original.eduWlList !== null) {
            row.original.eduWlList.forEach(data => {
                const conf = data.conf;
                if (conf !== null) {
                    row.originalWldMap.set(conf, data);
                }
            })
        }
    }

  for (const [eadC, eadD] of row.wldMap.entries()) {
    const activeHours = eadC.hours?.value ?? null;
    const value = eadD.value;
    if (value !== null) {
      row.activeHours = activeHours;
      break;
    }
  }
}


@Component({
    computed: {
        codes() {
            return codes
        }
    },
    components: {
        ListItemPart,
        ListItem,
        EduSubjectCell,
        EducationLevelCell,
        AddAttrField,
        'date-picker': Datepicker,
        DepartmentPositionCell,
        DossierTextView,
        GaDepartmentSelection,
        Overlay,
        Pagination: PaginationPages,
        SingleOrgField,
        SingleVersionField
    }
})
export default class Page extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('selectedOrg', (selectedOrg: Org | null) => {
            store.org = selectedOrg;
            store.conditionExp = null
            this.idRegion  = this.requiredRegionId
            this.selectedVersion = null;
        });

        this.$watch('selectedVersion', (selectedVersion: Version | null) => {
            store.version = selectedVersion;
            this.selectedGaDepartmentList = []
            this.departmentBreadcrumbs = [];
            if (selectedVersion !== null){
                this.loadEadC();
                this.loadWlc();
            }
        });

        this.$watch('selectedAbpCode', () => { this.tryReloadBudgetPrograms(); });

        this.$watch('selectedGaDepartmentId', () => {
            this.rows = [];
            this.reload();
            if (this.selectedGaDepartmentId === null) {
                this.departmentBreadcrumbs = [];
            } else {
                this.reloadDepartmentBreadcrumbs();
            }
        });

        this.$watch('employeePositionMode', (employeePositionMode: employeePositionMode) => {
            store.employeeMode = employeePositionMode
            this.page = 0;
            if (!this.rowsLoading) {
                this.reload();
            }
        });

        this.$watch('itemsPerPage', () => {
            this.reload();
        });

        this.$watch('selectedGaDepartmentList', () => {
            store.gaDepartmentList = this.selectedGaDepartmentList
        });

        this.$watch('departmentBreadcrumbs', () => {
            store.subDepartments = this.departmentBreadcrumbs
        });

        this.$watch('rowsRenderTimeout', (n: number | null, o: number | null) => {
            if (o !== null) {
                clearTimeout(o);
            }
        });

        this.$watch('dossierModalVisible', (newValue: boolean) => {
            if (!newValue) this.dossierClosed();
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.loadMilitaryRanks();
        this.loadMilitaryTitles();
        if (this.selectedOrg !== null) {
            this.reloadOrgTypeCode()
            this.reloadEduSubjects()
            this.tryReloadBudgetPrograms();
            this.loadWlc()
            this.loadEadC();
        }
    }
    // endregion

    /** АБП - образование */
    readonly  education = '261';

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Утилиты
    private i18n = new I18n('modules.budget.staffing_table.data');

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private selectAllOnClick = selectAllOnClick;

    private get isKazakh(): boolean {
        return (this.$i18n.locale.trim().toLowerCase() === 'kk');
    }

    private i18nDb = i18nDb;

    private t(key: string, ...args: Array<unknown>): string {
        return this.i18nDb.translateByKey(key, ...args);
    }
    // endregion

    private parseDate(date: string): Date | null {
            const parts = date.split('.');
            if (parts.length === 3) {
                const year = parseInt(parts[0]);
                const month = parseInt(parts[1]) - 1;
                const day = parseInt(parts[2]);
                return new Date(year, month, day);
            } else {
                console.error('Cannot parse string to Date');
                return null;
            }
    }

    // region Выбор организации, версии
    private selectedOrg: Org | null = store.org;

    private selectedVersion: Version | null = store.version;

    private get selectedGu(): Dict.Gu | null {
        if (this.selectedOrg === null) return null;
        return this.selectedOrg.gu;
    }

    private get requiredRegionId(): string | null {
        const gu = this.selectedGu;
        if (gu === null) return null;

        let result: string;
        if (gu.budgetType === '02') {
            const idRegion = gu.idRegion;
            const idRegionPart = (
                idRegion.length > 2 ?
                    idRegion.substr(0, 2)
                    :
                    idRegion
            );
            result = idRegionPart + '0101';
        } else {
            result = gu.idRegion;
        }

        return result;
    }

    private get selectedGuCode(): string | null {
        const org = this.selectedOrg;
        if (org === null) return null;

        switch (org.type) {
            case 'GU':
                return org.gu.code;
            case 'KGKP':
                return (org.kgkp.codeGuOwner ?? org.kgkp.codeGu ?? null);
            default:
                return null;
        }
    }

    private get selectedAbpCode(): string | null {
        const guCode = this.selectedGuCode;
        if ((guCode === null) || (guCode.length < 3)) return null;

        return guCode.substring(0, 3);
    }
    // endregion


    // region Выбор подразделения ГУ
    private gaDepartmentsModalVisible = false;
    private modalGaDepartmentList = new Array(...store.gaDepartmentList);
    private selectedGaDepartmentList = store.gaDepartmentList;

    private get selectedGaDepartment(): Department | null {
        if (this.selectedGaDepartmentList.length === 0) {
            return null;
        }
        return this.selectedGaDepartmentList[0];
    }

    private get selectedGaDepartmentId(): number | null {
        if (this.selectedGaDepartment === null) {
            return null;
        }
        return this.selectedGaDepartment.id;
    }

    private get selectedGaDepartmentText(): string | null {
        if (this.selectedGaDepartment === null) {
            return null;
        }

        let title: string;
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            title = this.selectedGaDepartment.nameKk;
        } else {
            title = this.selectedGaDepartment.nameRu;
        }

        return title;
    }

    private applyGaDepartmentSelection() {
        this.selectedGaDepartmentList = new Array(...this.modalGaDepartmentList);
        this.$nextTick(() => {
            if (this.gaDepartmentsModalVisible) {
                this.gaDepartmentsModalVisible = false;
            }
        });
    }

    private resetGaDepartmentSelection() {
        this.modalGaDepartmentList = new Array(...this.selectedGaDepartmentList);
        this.$nextTick(() => {
            if (this.gaDepartmentsModalVisible) {
                this.gaDepartmentsModalVisible = false;
            }
        });
    }

    private getGaDepartmentFullText(department: Department): string {
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            return department.nameKk;
        }
        return department.nameRu;
    }

    private getGaDepartmentText(department: Department): string {
        const result = this.getGaDepartmentFullText(department);
        if (result.length > 50) {
            return result.substr(0, 47) + '...';
        }
        return result;
    }

    private toggleGaDepartmentSelection(department: Department) {
        let index: number | undefined;
        for (let i = 0; i < this.modalGaDepartmentList.length; i++) {
            const selectedDepartment = this.modalGaDepartmentList[i];
            if (selectedDepartment.id === department.id) {
                index = i;
                break;
            }
        }

        if (index === undefined) {
            this.modalGaDepartmentList = [department];
        } else {
            this.modalGaDepartmentList.splice(index, 1);
            this.modalGaDepartmentList = [...this.modalGaDepartmentList];
        }
    }
    // endregion


    // region "Хлебные крошки" для подразделений
    // noinspection JSMismatchedCollectionQueryUpdate
    private departmentBreadcrumbs = new Array(...store.subDepartments);

    private loadingDepartmentBreadcrumbs = false;

    private reloadDepartmentBreadcrumbs() {
        if (this.loadingDepartmentBreadcrumbs) {
            console.error('Cannot reload department breadcrumbs - another loading is running');
            return;
        }

        const selectedGaDepartmentId = this.selectedGaDepartmentId;
        if (selectedGaDepartmentId === null) {
            console.error('Cannot reload department breadcrumbs - selected department ID is null');
            return;
        }

        this.loadingDepartmentBreadcrumbs = true;
        this.departmentBreadcrumbs = [];
        Ax<Array<Department>>(
            { url: `/api/budget/staffing_table/gu-department/path/${selectedGaDepartmentId}` },
            data => { this.departmentBreadcrumbs = data; },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_department_breadcrumbs'), error.toString()),
            () => { this.loadingDepartmentBreadcrumbs = false; }
        );
    }

    private departmentBreadcrumbClicked(department: Department) {
        if (department.id === this.selectedGaDepartmentId) {
            this.gaDepartmentsModalVisible = true;
        } else {
            this.selectedGaDepartmentList = [department];
            this.modalGaDepartmentList = [department];
        }
    }

    private getFullDepartmentBreadcrumbText(department: Department): string {
        let title: string;
        if (this.i18n.isKazakh) {
            title = department.nameKk;
        } else {
            title = department.nameRu;
        }
        return `#${department.id}. ${title}`;
    }

    private getDepartmentBreadcrumbText(department: Department): string {
        const fullText = this.getFullDepartmentBreadcrumbText(department);
        if (fullText.length > 50) {
            return fullText.substring(0, 47).trim() + '...';
        } else {
            return fullText;
        }
    }
    // endregion

    //  Код организации
  private orgTypeCode: string | null = null

  private loadingOrgTypeCode = false;

  private reloadOrgTypeCode() {
    if (this.loadingOrgTypeCode) {
      console.error('Cannot reload orgTypeCode - another loading is running');
      return;
    }

    const version = this.selectedVersion;
    if (version === null) {
      console.error('Cannot reload orgTypeCode - version is null');
      return;
    }

    this.loadingOrgTypeCode = true;
    Ax<string>(
        {
          method: "GET",
          url: `/api/budget/staffing_table/education-type-code/${version.id}` },
        orgTypeCode => {
            this.orgTypeCode = orgTypeCode
        },
        error => this.toast('danger', this.i18n.translate('error.cannot_load_org_type_code'), error.toString()),
        () => {
          this.loadingOrgTypeCode = false;
        }
    );
  }

  readonly eduSubjMap: Map<string, Dict.StEduSubject> = new Map<string, Dict.StEduSubject>();

  private loadingEduSubjects = false;

  private reloadEduSubjects() {
    if (this.loadingEduSubjects) {
      console.error('Cannot reload map of subjects - another loading is running');
      return;
    }
    this.loadingEduSubjects = true;
    Ax<Dict.StEduSubject[]>(
        {
          method: "GET",
          url: `/api/budget/staffing_table/all-education-subjects`
        },
        data => {
          data.forEach(subject => {
            this.eduSubjMap.set(subject.code, subject)
          })
        },
        error => this.toast('danger', this.i18n.translate('error.cannot_load_subjects'), error.toString()),
        () => {
          this.loadingEduSubjects = false;
        }
    );
  }


    private get loading(): boolean {
        return (
            this.rowsLoading
            ||
            this.eadCLoading
            ||
            this.militaryRanksLoading || this.militaryTitlesLoading
            ||
            this.loadingDepartmentBreadcrumbs
            ||
            this.budgetProgramsLoading
        );
    }


    // noinspection GrazieInspection
    // region Индивидуальные доп. атрибуты - предопределенные - настройка
    private eadCLoading = false;
    private eadCLoaded = false;
    private eadCList: Array<Ead.EadC> = [];

    private wlcLoading = false;
    private wlcList: Array<Edu.WlC> = [];

    private  attrKindKeys: string[] = ['ADD_PAYMENT', 'ALLOWANCE', 'ADD_FLAG', 'BENEFIT', 'COMPENSATION', 'MISC', 'SEASONAL_EMPLOYEE', 'HOURS'];

    private  attrKindKeysMap = new Map<string, boolean>();

    private get eadCMap(): Map<number, Ead.EadC> {
        const result = new Map<number, Ead.EadC>();
        this.eadCList.forEach(item => {
            const id = item.id;
            if (id !== null) {
                result.set(id, item);
            }
        });
        return result;
    }

    private get wlcMap(): Map<number, Edu.WlC> {
        const result = new Map<number, Edu.WlC>();
        this.wlcList.forEach(item => {
            const id = item.id;
            if (id !== null) {
                result.set(id, item);
            }
        });
        return result;
    }

    private get hasEadC(): boolean {
        return this.eadCList.isNotEmpty;
    }

    isActivePremiumDismissedAgs(employee: Employee, code: string): boolean {
        const isNAdministrative = (employee.pos?.legalActMvdPosition === null && employee.pos?.legalActPosition === null);
        const isPremiumDismissedAgs = (code === 'PREMIUM_FOR_DISMISSED_AGS');
        return isNAdministrative && isPremiumDismissedAgs;
    }

  private hasFactWorkLoad(eadC: Ead.EadC): boolean {
      return ((eadC.calcHint !== null && eadC.calcHint.includes('FACT_WORKLOAD')) && this.employeePositionMode === 'TARIFFICATION');
  }

    // Премия для уволенных АГС доступна только для Агс, Агс Мвд должностей
    private isInputFieldVisible(code: string, emp:Employee): boolean {
        return !(this.isActivePremiumDismissedAgs(emp, code) || (this.isTariffication() && code === 'EDUCATOR'));
    }

 private isEduTariffication(): boolean {
      return this.employeePositionMode === 'TARIFFICATION'
          &&
          this.selectedAbpCode === this.education
  }

    private isEduTariffSubject(): boolean {
        return (
            this.employeePositionMode === 'TARIFFICATION' &&
            this.selectedAbpCode === this.education &&
            (this.orgTypeCode !== null && !['01', '07', '08'].includes(this.orgTypeCode))
        );
    }

  private isRequiredSubject(): boolean {
    return ((this.orgTypeCode !== null && ['02', '03', '04', '05', '06'].includes(this.orgTypeCode) && this.employeePositionMode === 'TARIFFICATION'));
  }

    private isTariffication(): boolean {
        return this.employeePositionMode === 'TARIFFICATION'
    }

    private get eadCListSorted(): Array<Ead.EadC> {
        return this.eadCList.sort((eadC1, eadC2) => {
            const name1 = eadC1.ead?.nameRu ? eadC1.ead.nameRu : '';
            const name2 = eadC2.ead?.nameRu ? eadC2.ead.nameRu : '';
            if (name1 > name2) {
                return 1;
            }
            if (name2 > name1) {
                return -1;
            }
            return 0;
        });
    }

    private loadEadC() {
        if (this.eadCLoading) {
            console.error('Cannot load EAD - another loading is running');
            return;
        }

        const org = this.selectedOrg;
        if (org === null) {
            console.error('Cannot load EAD - organization is null');
            return;
        }

        const selectedVersion = this.selectedVersion;
        if (selectedVersion === null) {
            console.error('Cannot load EAD - selectedVersion is null');
            return;
        }

        this.eadCLoading = true;
        Ax<Array<Ead.EadC>>(
            {
                url: `/api/budget/staffing_table/config/${selectedVersion.id}/ead-c/list`
            },
            list => {
                this.eadCList = list;
                this.isActiveKind();
                this.eadCLoaded = true;
                this.reload();
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_ead'), error.toString()),
            () => {
                this.eadCLoading = false;
            }
        );
    }
    // endregion

  private isActiveFactHours(wlc: Edu.WlC, row: IRow): boolean {
    const value = wlc.hours?.value ?? null
    if (row.activeHours === null){
      return false
    }
    return value !== row.activeHours;

  }

    private getWlcHoursText(stEduHours: Dict.StEduHours | null): string {
        if (this.isKazakh) {
            return `${stEduHours?.nameKk ?? '-'}`;
        }
        return   `${stEduHours?.nameRu  ?? '-'} (${stEduHours?.value} ч. ${stEduHours?.unitRu})`
    }

    private getWldValue(row: IRow, conf: Edu.WlC): number | null {
        return  row.wldMap.get(conf)?.value ?? null
    }

    private setWldValue(row: IRow, conf: Edu.WlC, value: null | number) {
        const wld: Edu.WlD = {
            id: null,
            value: value,
            emp: null,
            conf: conf,
        }
         const oldWld = row.wldMap.get(conf)
        // noinspection JSIncompatibleTypesComparison
         if (oldWld !== undefined) {
            wld.id = oldWld.id;
        }
         row.wldMap.set(conf, wld)

        this.applyChange(row);
    }

    private loadWlc() {
        if (this.wlcLoading) {
            console.error('Cannot load WLC - another loading is running');
            return;
        }

        const org = this.selectedOrg;
        if (org === null) {
            console.error('Cannot load WLC - organization is null');
            return;
        }

        const idVersion = this.selectedVersion?.id;
        if (idVersion === null || idVersion === undefined) {
            console.error('Cannot load WLC - selectedVersion is null');
            return;
        }
        this.wlcLoading = true;
        Ax<Array<Edu.WlC>>(
            {
                url: `/api/budget/staffing_table/edu-wl-c`,
                params: {
                    idVersion: idVersion,
                },
            },
            list => {
                this.wlcList = list;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_ead'), error.toString()),
            () => {
                this.wlcLoading = false;
            }
        );
    }
    // endregion


    // region Чины военнослужащих
    private militaryRanksLoading = false;

    // noinspection JSMismatchedCollectionQueryUpdate
    private militaryRanks: Dict.MilitaryRank[] = [];

    private loadMilitaryRanks() {
        if (this.militaryRanksLoading) {
            console.error('Cannot load military ranks - another loading is running');
            return;
        }

        this.militaryRanksLoading = true;
        Ax<Dict.MilitaryRank[]>(
            {
                url: '/api/budget/staffing_table/dict-military-rank-list'
            },
            list => {
                this.militaryRanks = list;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_military_ranks'), error.toString()),
            () => {
                this.militaryRanksLoading = false;
            }
        );
    }
    // endregion


    // region Звания военнослужащих
    private militaryTitlesLoading = false;

    // noinspection JSMismatchedCollectionQueryUpdate
    private militaryTitles: Dict.MilitaryTitle[] = [];

    private loadMilitaryTitles() {
        if (this.militaryTitlesLoading) {
            console.error('Cannot load military titles - another loading is running');
            return;
        }

        this.militaryTitlesLoading = true;
        Ax<Array<Dict.MilitaryTitle>>(
            {
                url: '/api/budget/staffing_table/dict-military-rank-list'
            },
            list => {
                this.militaryTitles = list;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_military_titles'), error.toString()),
            () => {
                this.militaryTitlesLoading = false;
            }
        );
    }
    // endregion


    // region Зарплатные формы
    private forms = salaryForms;

    // formOptions - пока нельзя удалять, выбор зарплатной формы скрыт временно
    private get formOptions(): Array<Comp.DropdownItemDef<Report.FormKey | null>> {
        const result: Array<Comp.DropdownItemDef<Report.FormKey | null>> = [];
        result.push({
            text: '',
            value: null,
        });

        this.forms.forEach(form => {
            const option: Comp.DropdownItemDef<Report.FormKey> = {
                text: this.i18n.enumTranslate('report_form_key', form),
                value: form,
            };
            result.push(option);
        });

        return result;
    }
    // endregion


    // region Бюджетные программы
    private budgetProgramsLoading = false;

    private budgetPrograms: Array<Dict.EbkFunc> = [];

    private get budgetProgramOptions(): Array<Comp.DropdownItemDef<Dict.EbkFunc | null>> {
        const result: Array<Comp.DropdownItemDef<Dict.EbkFunc | null>> = [];
        result.push({
            value: null,
            text: '',
        });

        const isKazakh = this.i18n.isKazakh;
        this.budgetPrograms.forEach(budgetProgram => {
            const title = (isKazakh ? budgetProgram.nameKk : budgetProgram.nameRu) ?? '';

            let code = String(budgetProgram.prg);
            while (code.length < 3) {
                code = '0' + code;
            }

            const option: Comp.DropdownItemDef<Dict.EbkFunc> = {
                text: `${code} - ${title}`,
                value: budgetProgram,
            };

            result.push(option);
        });

        return result;
    }

    private get budgetProgramMapByCode(): Map<number, Dict.EbkFunc> {
        const result = new Map<number, Dict.EbkFunc>();

        this.budgetPrograms.forEach(budgetProgram => {
            const code = budgetProgram.prg;
            if (code === null) return;

            result.set(code, budgetProgram);
        });

        return result;
    }


    private tryReloadBudgetPrograms() {
        if (this.selectedOrg === null) return;
        this.reloadBudgetPrograms();
    }

    private reloadBudgetPrograms() {
        if (this.budgetProgramsLoading) {
            console.error('Cannot reload budget programs - another loading is running');
            return;
        }

        const selectedAbpCode = this.selectedAbpCode;
        if (selectedAbpCode === null) {
            console.error('Cannot reload budget programs - ABP code is null');
            return;
        }

        this.budgetPrograms = [];
        this.budgetProgramsLoading = true;
        Ax<Array<Dict.EbkFunc>>(
            { url: `/api/budget/staffing_table/report/budget-programs?abp-code=${selectedAbpCode}&date=${Date.now()}` },
            data => { this.budgetPrograms = data; },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_budget_programs'), error.toString()),
            () => { this.budgetProgramsLoading = false; }
        );
    }


    private getBudgetProgramByCode(budgetProgramCode: number): Dict.EbkFunc | null {
        const result = this.budgetProgramMapByCode.get(budgetProgramCode);
        return (result ?? null);
    }

    private setBudgetProgramCode(row: IRow, budgetProgram: Dict.EbkFunc | null) {
        const budgetProgramCode = (budgetProgram?.prg ?? null);
        this.applyChange(row, { budgetProgram: budgetProgramCode, eduAdditionalBudgetProgram: null });
    }

    private getEduAdditionalBudgetProgramOptions(mainBudgetProgramCode: unknown): Array<Comp.DropdownItemDef<Dict.EbkFunc | null>> {
        return this.budgetProgramOptions
            .filter((option) => (option.value?.prg !== mainBudgetProgramCode));
    }

    private setEduAdditionalBudgetProgramCode(row: IRow, budgetProgram: Dict.EbkFunc | null) {
        const budgetProgramCode = (budgetProgram?.prg ?? null);
        this.applyChange(row, { budgetProgram: null, eduAdditionalBudgetProgram: budgetProgramCode });
    }
    // endregion

    /**
     * Режим отображения сотрудников:
     *
     *  "STAFF_TAB" - отображение сотрудников с "обычными" должностями (не должностями в образовании);
     * "TARIFFICATION" - отображение сотрудников с должностями в образовании.
     */
    private employeePositionMode = store.employeeMode ?? 'STAFF_TAB';


    private get employeePositionOptions(): Array<Comp.DropdownItemDef<employeePositionMode>> {
        return [
            // Штатное расписание
            {
                text: this.i18n.translate('edu_tariff_option__staff_tab'),
                value: 'STAFF_TAB',
            },

            // Тарификация
            {
                text: this.i18n.translate('edu_tariff_option__tariffication'),
                value: 'TARIFFICATION',
            },
        ];
    }
    // endregion


    // region Навигация по страницам
    private itemsPerPage = 35;

    private itemsPerPageOptions: Array<Comp.DropdownItemDef<number>> = [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '35', value: 35 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: 'Все', value: ALL_ITEMS }
    ];

    private page = 0;

    private itemCount = 0;

    private onPageChanged(page: number) {
        this.page = page;
        this.reload();
    }
    // endregion


    // region Должности
    private get positionFilterHints(): Array<string> {
        const result: Array<string> = [];

        if (this.selectedAbpCode === '261') {
            let positionFilter: Position.Filter;
            if (this.employeePositionMode === 'STAFF_TAB') {
                positionFilter = 'NO_EDU';
            } else {
                positionFilter = 'ONLY_EDU';
            }
            result.push(positionFilter);
        }

        return result;
    }
    // endregion


    // region Строки
    private get fields(): Comp.TableFieldDef[] {
        const dataField = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.i18n.translate(`table_fields.${i18nKey}`)
            };
        };

        const bonusVisible = false;

        const result: Array<Comp.TableFieldDef> = [
            // ID
            dataField('id', 'id'),

            // ФИО
            dataField('fullName', 'full_name'),

            // Вакансия: Количество лет-мес
            dataField('experienceVacancy', 'experience_vacancy'),

            // Должность
            dataField('pos', 'pos'),

            // Дата приема на государственную службу
            dataField('admissionDate', 'admission_date'),

            // Решение комиссии: Дата
            dataField('cdDate', 'cd_date'),

            // Решение комиссии: Количество лет
            dataField('cdYears', 'cd_years'),

            // Решение комиссии: Количество месяцев
            dataField('cdMonths', 'cd_months'),

            // Решение комиссии: Количество дней
            dataField('cdDays', 'cd_days'),
        ];

        result.push(dataField('workStartDate', 'work_start_date'));

        // Дата окончания работы
        result.push(dataField('workEndDate', 'work_end_date'));

        // Премирование
        if (bonusVisible) result.push(dataField('bonus', 'bonus'));

          // Ставка
        if (!this.isTariffication()) result.splice(5, 0, dataField('timeRate', 'time_rate'));

         // Образование, поле доступно только режиме тарификации
        if (this.isEduTariffication()) result.splice(3, 0, dataField('educationLevel', 'education_level'));

        // Предмет, поле доступно только режиме тарификации
        if (this.isEduTariffSubject()) result.splice(5, 0, dataField('educationSubject', 'education_subject'));

        // Действия
        result.push({ key: '_actions', label: '' });

        return result;
    }

    // noinspection JSMethodCanBeStatic
    private getColumnWidth(key: string): string {
        switch (key) {
            case 'data.id':
            case '_actions':
                return '0';
            case 'data.admissionDate':
            case 'data.takingOfficeDate':
                return '500px';
            case 'data.region':
            case 'data.pos':
            case 'data.educationLevel':
            case 'data.educationSubject':return '2000px';
            case 'data.fullName':return '2000px';
            default:
                return '';
        }
    }

    private rowsRenderTimeout: number | null = null;

    private scheduleRowsRendering() {
        this.rowsRenderTimeout = setTimeout(() => {
            this.rowsRenderTimeout = null;
            this.rows = new Array(...this.rows);
        });
    }

    private rowsLoading = false;

    private rows: IRow[] = [];

    private applyChange(row: IRow, changes?: IChanges, skipRerender?: true) {

        const errorKey = (relativeErrorKey: string) => `modules.budget.staffing_table.data.error.${relativeErrorKey}`;

        // region Сброс данных строки
        delete row._rowVariant;
        row.errors = emptyErrors();
        // endregion

        // region Применение изменений
        if (changes !== undefined) {
            if (changes.admissionDate !== undefined) {

                if (changes.admissionDate === null) {
                    row.data.admissionDate = null
                } else {
                    row.data.admissionDate = updateFormattedDate(changes.admissionDate);
                }
            }
            if (changes.creationDate !== undefined) {
                row.data.creationDate = changes.creationDate;
            }
            if (changes.fullName !== undefined) {
                row.data.fullName = changes.fullName;
            }
            // noinspection JSIncompatibleTypesComparison
            if (changes.retiree !== undefined) {
                row.data.retiree = changes.retiree;
            }
            if (changes.pos !== undefined) {
                row.data.pos = changes.pos;
            }
            if (changes.takingOfficeDate !== undefined) {
                row.data.takingOfficeDate = changes.takingOfficeDate;
            }
            if (changes.timeRate !== undefined) {
                if ((changes.timeRate !== null) && (typeof changes.timeRate === 'number')) {
                    row.data.timeRate = changes.timeRate;
                } else {
                    row.data.timeRate = null;
                }
            }
            if (changes.cdDate !== undefined) {
                if (changes.cdDate === null) {
                    changes.cdYears = null
                    changes.cdMonths = null
                    changes.cdDays = null
                    row.data.cdDate = null
                } else {
                    row.data.cdDate = updateFormattedDate(changes.cdDate)
                }
            }
            if (changes.cdYears !== undefined) {
                if ((changes.cdYears === null) || (typeof changes.cdYears === 'number')) {
                    row.data.cdYears = changes.cdYears;
                } else {
                    row.data.cdYears = null;
                }
            }
            if (changes.cdMonths !== undefined) {
                if ((changes.cdMonths === null) || (typeof changes.cdMonths === 'number')) {
                    row.data.cdMonths = changes.cdMonths;
                } else {
                    row.data.cdMonths = null;
                }
            }
            if (changes.cdDays !== undefined) {
                if ((changes.cdDays === null) || (typeof changes.cdDays === 'number')) {
                    row.data.cdDays = changes.cdDays;
                } else {
                    row.data.cdDays = null;
                }
            }
            if (changes.experienceVacancy !== undefined) {
                if (changes.experienceVacancy !== null) {
                    row.data.admissionDate = null
                    row.data.cdDate = null
                    row.data.cdYears = null
                    row.data.cdMonths = null
                    row.data.cdDays = null
                    row.data.fullName = 'Вакансия';
                    row.data.experienceVacancy = changes.experienceVacancy;
                } else {
                    row.data.experienceVacancy = null;
                }
            }
            if (changes.workStartDate !== undefined) {
                if (changes.workStartDate === null) {
                    row.data.workStartDate = null;
                } else {
                    row.data.workStartDate =  updateFormattedDate(changes.workStartDate)
                }
            }
            if (changes.workEndDate !== undefined) {
                if (changes.workEndDate === null) {
                    row.data.workEndDate = null;
                    row.data.bonus = false;
                } else {
                    row.data.workEndDate = updateFormattedDate(changes.workEndDate)
                    row.data.bonus = true;
                }
            }
            if (changes.bonus !== undefined) {
                row.data.bonus = changes.bonus;
            }
            if (changes.pos?.workPositionRank !== undefined && changes.pos?.workPositionRank !== null) {
                row.data.admissionDate = null
                row.data.cdDate = null
                row.data.cdYears = null
                row.data.cdMonths = null
                row.data.cdDays = null
            }
            if (changes.form !== undefined) {
                row.data.form = changes.form;
            }
            if (changes.budgetProgram !== undefined) {
                row.data.budgetProgram = changes.budgetProgram;
            }

          if (changes.eduLevel !== undefined) {
             row.data.eduLevel = changes.eduLevel;
          }

            if (changes.eduSubjects !== undefined) {
                row.data.eduSubjects = changes.eduSubjects;
            }
            if (changes.eduAdditionalBudgetProgram !== undefined) {
                row.data.eduAdditionalBudgetProgram = changes.eduAdditionalBudgetProgram;
            }
        }
        // endregion


      // Проверка нормативной  нагрузки в часах фактической нагрузки
      (() => {
        for (const [eadC, eadD] of row.wldMap.entries()) {
          if (eadD.value !== null && eadC.hours?.value !== row.activeHours) {
            row.activeHours = eadC.hours?.value!!;
          }
        }
        const allNull = Array.from(row.wldMap.values()).every(wld => wld.value === null);

        // Флаг, показывает, что все поля пустые
        if (allNull) {
          row.activeHours = null
        }
      })();
      // endregion


      // region Основная валидация
        (() => {
            const now = new Date().getTime();

            if (row.data.fullName === '' || row.data.fullName === null) {
                row.errors.fullName = errorKey('empty_personal_info');
            }

          const takingOfficeDate = this.parseDate(row.data.takingOfficeDate);
          if (takingOfficeDate !== null && takingOfficeDate.getTime() > now) {
            row.errors.takingOfficeDate = errorKey('future_date');
          }

          if (row.data.pos === null) {
                row.errors.pos = errorKey('empty_job_title');
            }

            if (row.data.eduLevel === null && row.data.pos?.eduPosition !== null) {
            row.errors.eduLevel = errorKey('empty_field');
            }

          const creationDate = this.parseDate(row.data.creationDate);
          if (creationDate !== null && creationDate.getTime() > now) {
            row.errors.creationDate = errorKey('future_date');
          }


          if (row.data && row.data.admissionDate !== null) {
                const admissionDate = this.parseDate(row.data.admissionDate);
                if (admissionDate !== null && admissionDate.getTime() > now) {
                    row.errors.admissionDate = errorKey('future_date');
                }
            }

            if (row.data.cdDate !== null && row.data.admissionDate !== null) {
                row.errors.cdDate = errorKey('clean_date');
                row.errors.admissionDate = errorKey('clean_date');
            }

            if (row.data.cdDate === null && row.data.admissionDate === null && row.data.experienceVacancy == null) {
                row.errors.cdDate = errorKey('empty_date');
                row.errors.admissionDate = errorKey('empty_date');
            }

            if ((row.data.timeRate !== null && row.data.timeRate < 0)) {
                row.errors.timeRate = errorKey('available_range');
            }

            if ((row.data.experienceVacancy !== null && row.data.experienceVacancy < 0)) {
                row.errors.experienceVacancy = errorKey('available_range');
            } else if ((row.data.experienceVacancy !== null && row.data.experienceVacancy > 40)) {
                row.errors.experienceVacancy = errorKey('max_experience');
            }

            if ((row.data.cdYears !== null && row.data.cdYears > 99)) {
                row.errors.cdYears = errorKey('max_year');
            }

            if ((row.data.cdMonths !== null && row.data.cdMonths > 12)) {
                row.errors.cdMonths = errorKey('max_months');
            }

            if ((row.data.cdDays !== null && row.data.cdDays > 31)) {
                row.errors.cdDays = errorKey('max_days');
            }

            if (row.data.cdDate === null
                &&
                row.data.admissionDate === null
                &&
                row.data.pos?.workPositionRank === null
                &&
                row.data.experienceVacancy === null
            ) {
                row.errors.admissionDate = errorKey('empty_date');
                row.errors.cdDate = errorKey('empty_date');
            } else if (
                row.data.cdDate === null
                &&
                row.data.admissionDate === null
                &&
                row.data.experienceVacancy === null
            ) {
                row.errors.admissionDate = errorKey('empty_date');
            }

            if (row.data.cdDate !== null
                &&
                row.data.admissionDate === null
                &&
                row.data.cdYears === null
                &&
                row.data.cdMonths === null
                &&
                row.data.cdDays === null)
            {
                row.errors.cdDate = errorKey('add_date');
            }
            if (row.data.admissionDate !== undefined
                &&
                (row.data.cdDate === undefined || row.data.cdDate === null)
                &&
                (row.data.cdYears || row.data.cdMonths || row.data.cdDays !== null))
            {
                 row.errors.admissionDate = errorKey('clean_cdYears_cdMonths_cdDays');
            }

            if (row.data.timeRate !== null && row.data.timeRate > 2) {
                row.errors.timeRate = errorKey('timeRate_over_limit')
            }

            if (row.data.eduSubjects?.isEmpty && this.isRequiredSubject()) {
                row.errors.subjects = errorKey('empty_field')
            }

            if (row.data.workStartDate !== null && row.data.workEndDate !== null && row.data.workStartDate > row.data.workEndDate) {
                row.errors.workStartDate = errorKey('work_start_date_over_end_date');
            }
        })();

       // region Валидация дополнительных атрибутов
        (() => {
            for (const [eadC, eadD] of row.eadMap.entries()) {
                const ead = eadC.ead;
                if (ead === null) continue;

                if (eadD.startPeriod !== null && eadD.endPeriod !== null && eadD.startPeriod > eadD.endPeriod){
                    row.errors.eadErrorMap.set(ead.code, errorKey('start_period_over_ead'))
                }

                switch (ead.code) {
                    case 'SEASONAL_WORKER': {
                        if (eadD.valueNumber !== null && eadD.valueNumber > 12) {
                            row.errors.eadErrorMap.set(ead.code, errorKey('max_months'))
                        }
                    } break;
                }
            }

        })();

        row.invalid = (
            (row.errors.creationDate !== null)
            || (row.errors.admissionDate !== null)
            || (row.errors.takingOfficeDate !== null)
            || (row.errors.workStartDate !== null)
            || (row.errors.region !== null)
            || (row.errors.pos !== null)
            || (row.errors.timeRate !== null)
            || (row.errors.fullName !== null)
            || (row.errors.cdDate !== null)
            || (row.errors.cdYears !== null)
            || (row.errors.cdMonths !== null)
            || (row.errors.cdDays !== null)
            || (row.errors.experienceVacancy !== null)
            || (row.errors.eduLevel !== null)
            || (row.errors.subjects !== null)
            || (row.errors.eadErrorMap.size !== 0)
            || (row.errors.wldErrorMap.size !== 0)
        );
        // endregion

        // region Проверка, изменена ли строка
        row.changed = hasDifference(row);
        // endregion

      // region Проверка, изменена ли строка которая меняет позицию
      if (this.replaceRow !== null) {
        this.replaceRow.changed = hasDifference(this.replaceRow)
        if (this.replaceRow.changed || this.replaceRow._showDetails) {
          this.clearReplacementRow();
        }
      }
      // endregion

        // region Установка цвета строки (в зависимости от состояния)
        if (row.invalid) {
            row._rowVariant = 'danger';
        } else if (row.changed) {
            row._rowVariant = 'success';
        } else if (row.selected) {
            row._rowVariant = 'info';
        } else {
            row._rowVariant = null;
        }
        // endregion

        if (skipRerender !== true) {
            this.scheduleRowsRendering();
        }
    }

    // region Изменение порядка строк в таблице
    private movingRow: IRow | null = null;

    private replaceRow: IRow | null = null;

    private startRowMoving(row: IRow) {
        this.movingRow = row;
    }

    private startReplaceRow(row: IRow) {
        this.replaceRow = row;
        row._rowVariant = 'info';
    }

    private clearReplacementRow() {
        const replacedRow = this.replaceRow;
        if (replacedRow) {
            replacedRow._rowVariant = null;
            this.movingRow = null;
            this.replaceRow = null;
        }
    }

    private replaceRowPosition(row: IRow) {
        const upDateEmploysPosition = new Map<number, number>();
        const updatedRows = [...this.rows];
        const departmentId = this.selectedGaDepartment?.id

        if (departmentId === null) {
            console.error('Cannot replace position employee - selected GA departmentId is null');
            return;
        }

        if (this.replaceRow !== null) {
            const indexStartRow = this.rows.indexOf(this.replaceRow);
            const indexReplaceRow = this.rows.indexOf(row);

            const maxIndex = Math.max(indexStartRow, indexReplaceRow);
            const minIndex = Math.min(indexStartRow, indexReplaceRow);

            updatedRows.splice(indexStartRow, 1);

            if (indexStartRow > indexReplaceRow) {
                updatedRows.splice(minIndex, 0, this.replaceRow);
                const countRows = updatedRows.length

                for (let index = 0; index < countRows; index++) {
                    const updatedRow = updatedRows[index];
                    if (updatedRow.data.department?.id === departmentId) {
                        updatedRow.data.serialNumber = index;
                        upDateEmploysPosition.set(updatedRow.data.id!!, index);
                    }
                }
            } else {
                updatedRows.splice(maxIndex - 1, 0, this.replaceRow);
                const countRows = updatedRows.length

                for (let index = 0; index < countRows; index++) {
                    const updatedRow = updatedRows[index];
                    if (updatedRow.data.department?.id === departmentId) {
                        updatedRow.data.serialNumber = index;
                        upDateEmploysPosition.set(updatedRow.data.id!!, index);
                    }
                }
            }
            this.rows = updatedRows;
            this.savePosition(upDateEmploysPosition);
            this.clearReplacementRow();
        }
    }

    private savePosition(employsMap: Map<number, number>) {
        const employsMapObj = Object.fromEntries(employsMap);
        axios({
            url: "/api/budget/staffing_table/employs-position",
            method: "POST",
            data: employsMapObj,
        }).catch((error) => {
            this.toast("danger", this.i18n.translate(`error.cannot_save_serialNumber`), error.toString());
        })
    }
    // endregion

    private reload() {
        if (!this.eadCLoaded) {
            return;
        }

        if (this.rowsLoading) {
            return;
        }

        if (this.selectedGaDepartmentId === null) {
            return;
        }

        const url = prepareUrl(
            '/api/budget/staffing_table/staffing_table_items',
            paramMap => {
                paramMap.set('department-id', String(this.selectedGaDepartmentId));
                paramMap.set('items-per-page', String(this.itemsPerPage));
                paramMap.set('page', String(this.page));

                this.positionFilterHints.forEach((positionFilterHint) => {
                    paramMap.set('filter-hint', positionFilterHint);
                });
            },
        )

        this.rowsLoading = true;
        Ax<Utils.PaginationList<Employee>>(
            { url },
            list => {
                this.page = list.page;
                this.itemsPerPage = list.itemsPerPage;
                this.itemCount = list.itemCount;

                const rows: IRow[] = [];

                list.items.forEach(data => {
                    if (data.eadList !== null) {
                        data.eadList.forEach(eadDataItem => {
                            const confId = eadDataItem.conf?.id;
                            if ((confId === undefined) || (confId === null)) {
                                eadDataItem.conf = null;
                            } else {
                                eadDataItem.conf = this.eadCMap.get(confId) || null;
                            }
                        });
                    }

                    if (data.eduWlList !== null) {
                        data.eduWlList.forEach(eadDataItem => {
                            const confId = eadDataItem.conf?.id;
                            if ((confId === undefined) || (confId === null)) {
                                eadDataItem.conf = null;
                            } else {
                                eadDataItem.conf = this.wlcMap.get(confId) || null;
                            }
                        });
                    }

                    const row: IRow = {
                        id: String(data.id),
                        data,
                        original: copyData(data),
                        selected: false,
                        changed: false,
                        invalid: false,
                        _rowVariant: null,
                        errors: emptyErrors(),
                        inputValues: {},
                        eadMap: new Map(),
                        originalEadMap: new Map(),
                        wldMap: new Map(),
                        originalWldMap: new Map(),
                        activeHours: null
                    };
                    copyAddAttrFromDataToRow(this.eadCList, row);
                    copyEduFromDataToRow(this.wlcList, row)
                    rows.push(row);

                    this.applyChange(row, undefined, true);
                });

                this.rows = rows;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_items'), error.toString()),
            () => {
                this.clearReplacementRow()
                this.rowsLoading = false;
            }
        );
    }

    private add() {
        const now = ((): Date => {
            const date = new Date();
            date.setHoursChained(0, 0, 0, 0);
            return date;
        })();

        const department = this.selectedGaDepartment;
        if (department === null) {
            console.error('Cannot create employee - selected GA department is null');
            return;
        }

        const data: Employee = {
            id: null,
            creationDate: updateFormattedDate(now),
            admissionDate: null,
            takingOfficeDate: updateFormattedDate(now),
            fullName: null,
            region: null,
            pos: null,
            retiree: false,
            timeRate: 1,
            cdDate: null,
            cdYears: null,
            cdMonths: null,
            cdDays: null,
            vacancy: false,
            experienceVacancy: null,
            department: department,
            workStartDate: null,
            workEndDate: null,
            serialNumber: null,
            bonus: false,
            eduLevel: null,
            eduSubjects: [],
            eadList: [],
            eduWlList: [],
            form: null,
            budgetProgram: null,
            orgType: null,
            eduAdditionalBudgetProgram: null,
        };

        const row: IRow = {
            id: String(new Date().getTime()),
            data: data,
            original: null,
            selected: false,
            changed: false,
            invalid: false,
            _rowVariant: null,
            errors: emptyErrors(),
            inputValues: {},
            eadMap: new Map(),
            originalEadMap: new Map(),
            wldMap: new Map(),
            originalWldMap: new Map(),
            activeHours: null
        };

        this.rows.unshift(row);
        this.applyChange(row);
    }

    private cancel(row: IRow) {
        if (row.original === null) {
            const index = this.rows.indexOf(row);
            if (index >= 0) {
                this.rows.splice(index, 1);
            }
        } else {
            row.data = copyData(row.original);
            copyAddAttrFromDataToRow(this.eadCList, row);
            copyEduFromDataToRow(this.wlcList, row)
            this.applyChange(row);
        }
    }

    private copyItem(row: IRow) {
        const index = this.rows.indexOf(row);
        const data = copyData(row.data);
        const now = ((): Date => {
            const date = new Date();
            date.setHoursChained(0, 0, 0, 0);
            return date;
        })();

        data.id = null;
        data.fullName = null;
        data.creationDate = updateFormattedDate(now);
        data.admissionDate = null;
        data.takingOfficeDate = updateFormattedDate(now);
        data.workStartDate = null;
        data.cdYears = null;
        data.cdMonths = null;
        data.cdDays = null;
        data.cdDate = null;
        data.timeRate = 1;
        data.region = null;
        resetIdsAndEmpsToNull(data.eduWlList)

        const newRow: IRow = {
            id: String(new Date().getTime()),
            data: data,
            original: null,
            selected: false,
            changed: false,
            invalid: false,
            _rowVariant: null,
            errors: emptyErrors(),
            inputValues: {},
            eadMap: new Map(row.eadMap),
            originalEadMap: new Map(),
            wldMap: new Map(row.wldMap),
            originalWldMap: new Map(),
            activeHours: null
        };
        this.rows.splice(index, 0, newRow);
        this.applyChange(newRow);
    }

    private save(row: IRow) {
        if (row.invalid) {
            return;
        }

        if (this.rowsLoading) {
            return;
        }

        copyAddAttrFromRowToData(row);

        this.rowsLoading = true;
        Ax<number>(
            {
                url: '/api/budget/staffing_table/staffing_table_item',
                method: 'POST',
                data: row.data
            },
            id => {
                row.id = String(id);
                row.data.id = id;
                row.original = copyData(row.data);
                copyAddAttrFromDataToRow(this.eadCList, row);
                copyEduFromDataToRow(this.wlcList, row)
                this.applyChange(row);

                this.toast(
                    'success',
                    this.i18n.translate('item_saved'),
                    `ID - ${id}`
                );
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_save_item'), error.toString()),
            () => {
                this.rowsLoading = false;
            }
        );
    }

    private remove(row: IRow) {
        const id = row.data.id;
        if (id === null) {
            return;
        }

        if (this.rowsLoading) {
            return;
        }

        this.$bvModal
            .msgBoxConfirm(
                this.i18n.translate('removal_confirmation', [id]),
                { okVariant: 'danger' }
            )
            .then(userChoice => {
                if (userChoice === true) {
                    if (this.rowsLoading) {
                        return;
                    }

                    this.rowsLoading = true;

                    Ax(
                        {
                            url: `/api/budget/staffing_table/staffing_table_item/${id}`,
                            method: 'DELETE'
                        },
                        () => {
                            const index = this.rows.indexOf(row);
                            if (index >= 0) {
                                this.rows.splice(index, 1);
                            }

                            this.toast('success', this.i18n.translate('item_deleted'), `ID - ${id}`);
                        },
                        error => this.toast('danger', this.i18n.translate('error.cannot_delete_item', [id]), error.toString()),
                        () => {
                            this.rowsLoading = false;
                        }
                    );
                }
            });
    }

    private rowClicked(row: IRow) {
        row._showDetails = (!row._showDetails);
        this.applyChange(row);
    }

    private rowDetailsClose(row: IRow) {
        row._showDetails = false;
        this.applyChange(row);
    }

    // noinspection JSMethodCanBeStatic
    private getStEadText(stEad: Dict.StEad): string {
        if (this.isKazakh) {
            return stEad.nameKk;
        }
        return stEad.nameRu;
        }

    private isAddAttrVisible(row: IRow, eadC: Ead.EadC): boolean {
        const eadKey = eadC.ead!!.code;

        //  Исключение доп. атрибута "внештатный сотрудник" у госслужащих
        if (row.data.pos?.legalActPosition !== null && eadKey === 'NON_STAFF_MEMBER')
            return false;

       // У сотрудников образования не отображать атрибут "Внештатный сотрудник"
        if (row.data.pos?.eduPosition !== null && eadKey === 'NON_STAFF_MEMBER')
            return false;

        // доп.атрибут "квалифицированный рабочий" доступен только в рабочей должности
        if (row.data.pos?.workPositionRank === null && eadKey === 'SKILLED_WORKER')
            return false;

        // доп.атрибут "сезонный рабочий" доступен только в рабочей должности и гражданской"
        // noinspection RedundantIfStatementJS
        if ((row.data.pos?.workPositionRank === null) && (row.data.pos?.civilPosition === null) && (row.data.pos?.eduPosition === null) && (eadKey === 'SEASONAL_WORKER'))
            return false;


        if (this.isTariffication() && eadKey === 'EDUCATOR'){
            return false
        }

        // доп.атрибут "Сумма должностных окладов в месяц (коэффициент к ДО)" доступен только для для АГС, АГС МДВ
        return !((row.data.pos?.legalActMvdPosition === null && row.data.pos?.legalActPosition === null) && (eadKey === 'PREMIUM_FOR_ADMINISTRATIVE' || eadKey === 'PREMIUM_FOR_DISMISSED_AGS'));
    }

    private isActiveKind() {
        if (this.eadCList.length > 0) {
            this.attrKindKeysMap.clear()
            this.eadCList.forEach(eadC => {
                const kind = eadC.ead?.kind
                if (kind)
                    this.attrKindKeysMap.set(kind, true)
            })
        }
    }

    //  Валидация для закрытия полей для ввода данных
    private getValid(row: IRow): boolean {
        // noinspection RedundantIfStatementJS
        if (
            (row.data.admissionDate !== null)
            ||
            ((row.data.pos?.workPositionRank !== null) && (row.data.pos?.workPositionRank !== undefined))
            ||
            (row.data.experienceVacancy !== null)
        ) {
            return true;
        }
        return false;
    }

    private getValidCdDays(row: IRow): boolean {
        // noinspection RedundantIfStatementJS
        if (
            (!row.data.admissionDate && row.data.cdDate && row.data.cdDays && row.data.cdYears && row.data.cdMonths !== null)
                &&
            ((row.data.admissionDate !== null)
                ||
            ((row.data.pos?.workPositionRank !== null) && (row.data.pos?.workPositionRank !== undefined)))
        ) {
            return true;
        }
        return false;
    }

    // noinspection JSMethodCanBeStatic
    private getEadOptions(code: string): Array<Comp.DropdownItemDef<null | boolean | string | number | Date>> | null {
        const isKazakh = (this.$i18n.locale.trim().toLowerCase() === 'kk');
        const eadDropdownList = eadDropdownValueMap.get(code) ?? null;
        if (!eadDropdownList) return null;

        const result = eadDropdownList.map(item => ({
            text: (isKazakh ? item.nameKk : item.nameRu),
            value: getValueFromDropdown(item, item.ead.attrType)
        }));

        result.unshift({value: null, text: ''});

        return result;
    }

    // noinspection JSMethodCanBeStatic
    private getEadValue(row: IRow, conf: Ead.EadC): null | boolean | string | number | Date | Dict.MilitaryRank | Dict.MilitaryTitle {
        const type = getEadType(conf.ead);
        const data = row.eadMap.get(conf)
        switch (type) {
            case 'MILITARY_RANK':
                // noinspection JSIncompatibleTypesComparison
                return (data === undefined ? null : data.valueMilitaryRank);
            case 'MILITARY_TITLE':
                // noinspection JSIncompatibleTypesComparison
                return (data === undefined ? null : data.valueMilitaryTitle);
            default:
               return getFromStafftabAddAttrHolder(data, type)
        }
    }

  private getFactHours(row: IRow, conf: Ead.EadC): number | null {
    const data = row.eadMap.get(conf);
    return data?.factHours ?? null;
  }

    private getWlcHoursLabelText(stEduHours: Dict.StEduHours | null): string {
        if (this.isKazakh) {
            return `(${stEduHours?.value}  ${stEduHours?.unitKk})`;
        }
        return `(${stEduHours?.value} ч. ${stEduHours?.unitRu})`;
    }

  private setHoursValue(row: IRow, conf: Ead.EadC, hours: number | null) {
        const oldData = row.eadMap.get(conf) ?? null;
        // noinspection JSIncompatibleTypesComparison
        if (oldData !== null) {
            if (oldData.factHours !== hours){
                oldData.factHours = hours
                row.eadMap.set(conf, oldData)
            }
        }
          this.applyChange(row);
    }

    private getStartPeriod(row: IRow, conf: Ead.EadC): Date | null {
        const date = row.eadMap.get(conf)?.startPeriod ?? null;
        if (date) {
            return new Date(date);
        }
        return  null;
    }

    private getEndPeriod(row: IRow, conf: Ead.EadC): Date | null {
        const date = row.eadMap.get(conf)?.endPeriod ?? null;
        if (date) {
            return new Date(date);
        }
        return  null;
    }

    private setStartPeriod(row: IRow, conf: Ead.EadC, start: Date | null) {
        const oldData = row.eadMap.get(conf) ?? null;
        // noinspection JSIncompatibleTypesComparison
        if (oldData !== null) {
            if (oldData.startPeriod !== start?.getTime()){
                oldData.startPeriod = start?.getTime() ?? null
                row.eadMap.set(conf, oldData)
            }
        }
        this.applyChange(row);
    }

    private setEndPeriod(row: IRow, conf: Ead.EadC, ead: Date | null) {
        const oldData = row.eadMap.get(conf) ?? null;
        // noinspection JSIncompatibleTypesComparison
        if (oldData !== null) {
            if (oldData.endPeriod !== ead?.getTime()) {
                oldData.endPeriod = ead?.getTime() ?? null
                row.eadMap.set(conf, oldData)
            }
        }
        this.applyChange(row);
    }

    private setEadValue(row: IRow, conf: Ead.EadC, value: null | boolean | string | number | Date | Dict.MilitaryRank | Dict.MilitaryTitle) {
        const data: Ead.EadD = {
            id: null,
            emp: null,
            conf: conf,
            valueBool: null,
            valueText: null,
            valueNumber: null,
            valueSalaryRate: null,
            valueNormRate: null,
            valueDate: null,
            valueMilitaryRank: null,
            valueMilitaryTitle: null,
            calcHint: conf.calcHint,
            factHours: null,
            startPeriod: null,
            endPeriod: null
        };
        const oldData = row.eadMap.get(conf);
        row.eadMap.set(conf, data);
        // noinspection JSIncompatibleTypesComparison
        if (oldData !== undefined) {
            data.id = oldData.id;
            data.factHours = oldData.factHours
        }

        const type = getEadType(conf.ead);
        switch (type) {
            case 'MILITARY_RANK':
                data.valueMilitaryRank = (value as Dict.MilitaryRank | null);
                break;
            case 'MILITARY_TITLE':
                data.valueMilitaryTitle = (value as Dict.MilitaryTitle | null);
                break;
            default:
                 setToStafftabAddAttrHolder(data, type, (value as null | boolean | string | number | Date));
                break;
        }

        this.applyChange(row);
    }
    // endregion


    // region Импорт подготовленного файла
    private preparedFileImportModalVisible = false;

    private preparedFileToImport: (File | null) = null;

    private showPreparedFileImportDialog() {
        this.preparedFileToImport = null;
        this.preparedFileImportModalVisible = true;
    }

    private startPreparedFileImport() {
        const form = this.$refs.preparedFileImportForm;
        if (!(form instanceof HTMLFormElement)) {
            console.error('Cannot import prepared file - cannot form found by ref - it is not instance of HTMLFormElement', form);
            return;
        }
        form.submit();
    }
    // endregion


    // region Досье сотрудника
    private dossierModalVisible = false;
    private dossierDate: Date | null = null;
    private dossierEmployeeId: number | null = null;
    private variantBudget: BudgetVariants | null = null;
    private idRegion: string | null = this.requiredRegionId;


    private openDossier(row: IRow) {
        const id = row.data.id;
        if (id === null) return;

       const idRegion = this.idRegion
       if (idRegion === null) return;

        this.dossierEmployeeId = id;
        this.dossierModalVisible = true;
    }

    private dossierClosed() {
        this.dossierEmployeeId = null;
        this.dossierDate = null;
    }
    // endregion
}






























































































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { Comp, Position } from '../types';
import {staffTabAccess} from "@/modules/budget/staffing-table/common";


/**
 * Должность
 */
interface IPosition extends Position { id: number }

/**
 * Строка таблицы
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRow extends Comp.TableRow<IPosition> {}


const modelChangeEvent = 'change';
const hasDifference = (list1: IPosition[], list2: IPosition[]): boolean => {
    if (list1.length !== list2.length) {
        return true;
    }

    const ids1 = list1.map(item => item.id).sort();
    const ids2 = list2.map(item => item.id).sort();
    for (let i = 0; i < ids1.length; i++) {
        if (ids1[i] !== ids2[i]) {
            return true;
        }
    }

    return false;
};


@Component
export default class DepartmentPositionSelection extends Vue {
    // region Модель и свойства
    @Model(modelChangeEvent, {
        type: Array,
        default: () => [],
        required: true,
        validator(value: any): boolean {
            if (!Array.isArray(value)) {
                console.error('"value" is not array', value);
                return false;
            }

            for (let i = 0; i < value.length; i++) {
                const item = value[i];

                if (!(item instanceof Object)) {
                    console.error(`"value[${i}]" is not object`, value);
                    return false;
                }

                const region = item as Partial<IPosition>;

                if (typeof region.id !== 'number') {
                    console.error(`"value[${i}].id" is not number`, value);
                    return false;
                }

                if (typeof region.nameKk !== 'string') {
                    console.error(`"value[${i}].nameKk" is not string`, value);
                    return false;
                }

                if (typeof region.nameRu !== 'string') {
                    console.error(`"value[${i}].nameRu" is not string`, value);
                    return false;
                }
            }

            return true;
        }
    })
    public readonly value!: IPosition[];

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly multiple!: boolean;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly selectOnRowClick!: boolean;

    @Prop({
        type: Number,
        required: true
    })
    public readonly versionId!: number;

    @Prop({
        type: Array,
        required: false,
        default: () => null,
    })
    public readonly filterHints!: Array<string> | null;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.selectedPositions = this.value;

        this.$watch('value', () => {
            if (hasDifference(this.value, this.selectedPositions)) {
                this.selectedPositions = new Array(...this.value);
            }
        });

        this.$watch('multiple', () => {
            if ((!this.multiple) && (this.selectedPositions.length > 1)) {
                this.selectedPositions = [this.selectedPositions[0]];
            }
        });

        this.$watch('departmentId', () => {
            this.reload();
        });

        this.$watch('selectedPositions', () => {
            if (hasDifference(this.value, this.selectedPositions)) {
                this.$emit(modelChangeEvent, this.selectedPositions);
            }
        });

        this.$watch('trimmedSearchText', () => {
            this.reload();
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.reload();

        setTimeout(() => {
            const searchTextField = this.$refs.searchTextField;
            if (searchTextField instanceof Vue) {
                const el = (searchTextField as Vue).$el;
                if (el instanceof HTMLInputElement) {
                    el.focus();
                }
            }
        });
    }
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(
            `modules.budget.staffing_table.ga_department_positions.*DepartmentPositionSelection*.${key}`,
            values
        ));
    }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private getFunctionalBlockAndPosLevelTextForRow(row: IRow): string {
        if (row.data.legalActPosition !== null) {
            if (row.data.legalActPosition.kind === 'ADMINISTRATIVE') {
                const funcBlock = row.data.legalActPosition.funcBlock ?? '';
                const level = row.data.legalActPosition.posLevel;
                return funcBlock + '-' + level;
            }

            if (row.data.legalActPosition?.kind === 'POLITICAL') {
                return `${row.data.legalActPosition.posLevel}`;
            }

            if (row.data.legalActPosition?.kind === 'DEPUTY') {
                return `${row.data.legalActPosition.posLevel}`;
            }
        }

        if (row.data.workPositionRank !== null) {
            return `${row.data.workPositionRank}`;
        }

        if (row.data.civilPosition !== null) {
            let level: any
            let echelon: any
            let echelonAndLevel: any = row.data.civilPosition.funcBlock;
            if (row.data.civilPosition.level !== null) {
                if (row.data.civilPosition.level.type === 'LEVEL') {
                    if (row.data.civilPosition.level.parent !== null) {
                        if (row.data.civilPosition.level.parent.type === 'ECHELON') {
                            echelon = row.data.civilPosition.level.parent.code;
                            level = row.data.civilPosition.level.code;
                        } else {
                            echelon = null;
                            level = row.data.civilPosition.level.code;
                        }
                    }
                } else {
                    echelon = row.data.civilPosition.level.code;
                }

                if ((echelon !== null) && (echelon !== undefined)) {
                    echelonAndLevel += echelon;
                }
                if ((level!==null) && (level !== undefined)) {
                    echelonAndLevel += '-' + level;
                }
            }
            return echelonAndLevel;
        }

        if (row.data.eduPosition) {
          let level: any
          let echelon: any
          let echelonAndLevel: any = row.data.eduPosition.funcBlock;
          if (row.data.eduPosition.level !== null) {
            if (row.data.eduPosition.level.type === 'LEVEL') {
              if (row.data.eduPosition.level.parent !== null) {
                if (row.data.eduPosition.level.parent.type === 'ECHELON') {
                  echelon = row.data.eduPosition.level.parent.code;
                  level = row.data.eduPosition.level.code;
                } else {
                  echelon = null;
                  level = row.data.eduPosition.level.code;
                }
              }
            } else {
              echelon = row.data.eduPosition.level.code;
            }

            if ((echelon !== null) && (echelon !== undefined)) {
              echelonAndLevel += echelon;
            }
            if ((level!==null) && (level !== undefined)) {
              echelonAndLevel += '-' + level;
            }
          }
          return echelonAndLevel;
        }
        return '';
    }

    private get isTariffication(): boolean {
      return !this.filterHints?.includes('ONLY_EDU')
    }

    // noinspection JSMethodCanBeStatic
    private getPositionCategoryTextForRow(row: IRow): string {
        if ((row.data.legalActPosition !== null) && (row.data.legalActPosition.category !== null)) {
            const categoryNumber = row.data.legalActPosition.categoryNumber ?? '';
            const categoryCode = row.data.legalActPosition.category.code ?? '';
            return categoryCode + '-' + categoryNumber;
        }

        // noinspection JSIncompatibleTypesComparison
        if ((row.data.mvdPosition !== null) && (row.data.mvdPosition.category !== null)) {
            return row.data.mvdPosition.category ?? '';
        }

        return '';
    }
    // endregion


    // region Выбранные элементы
    private selectedPositions: IPosition[] = [];

    private get selectedPositionIds(): number[] {
        const result: number[] = [];

        this.selectedPositions.forEach(position => {
            result.push(position.id as number);
        });

        return result;
    }

    private getPositionFullText(position: IPosition): string {
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            return position.nameKk;
        }
        return position.nameRu;
    }

    private getPositionText(position: IPosition): string {
        const text = this.getPositionFullText(position);
        if (text.length > 50) {
            return text.substr(0, 47) + '...';
        }
        return text;
    }

    private unselect(position: IPosition) {
        const index = this.selectedPositions.indexOf(position);
        if (index >= 0) {
            this.selectedPositions.splice(index, 1);
        }

        const row = this.findByPositionId(position.id);
        if (row !== null) {
            row.selected = false;
            this.scheduleRowsRendering();
        }
    }
    // endregion


    // region Текст для поиска
    private searchText = '';

    private get trimmedSearchText(): string {
        return this.searchText.trim();
    }
    // endregion


    // region Строки таблицы
    private get fields(): Comp.TableFieldDef[] {
        const dataField = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.getTranslate(`table_fields.${i18nKey}`)
            };
        };
      const result: Array<Comp.TableFieldDef> = [

        {
          key: 'selected',
          label: ''
        },

        // ID
        dataField('id', 'id'),

        // Функциональный блок и уровень должности звено
        dataField('funcBlock', 'func_block'),

        // Название на русском
        dataField('nameRu', 'name_ru'),

        // Название на казахском
        dataField('nameKk', 'name_kk'),
      ]

      // // Категория должностей
      if (this.isTariffication) result.splice(2, 0, dataField('posLevel', 'pos_category'));


      return result
    }

    private rowsRenderTimeout: number | null = null;

    private scheduleRowsRendering() {
        this.rowsRenderTimeout = setTimeout(() => {
            this.rowsRenderTimeout = null;

            const scan = (rows: IRow[]) => {
                rows.forEach(row => {
                    row.selected = this.selectedPositionIds.includes(row.data.id);
                });
            };
            scan(this.rows);

            this.rows = new Array(...this.rows);
        });
    }

    private loading = false;

    private rows: IRow[] = [];

    private findByPositionId(id: number): IRow | null {
        for (const row of this.rows) {
            if (row.data.id === id) {
                return row;
            }
        }
        return null;
    }

    private reload() {
        if (this.loading) {
            return;
        }

        let url = `/api/budget/staffing_table/gu-positions/version/${this.versionId}`;
        let firstParam = true;
        const addParam = (key: string, value: string) => {
            if (firstParam) {
                url += '?';
                firstParam = false;
            } else {
                url += '&';
            }
            url += key + '=' + encodeURI(value);
        };


        if (this.trimmedSearchText.length > 0) {
            addParam('search-text', this.trimmedSearchText);
        }
        if (this.filterHints !== null) {
            this.filterHints.forEach((filterHint) => {
                addParam('filter-hint', filterHint);
            });
        }


        this.loading = true;

        Ax<IPosition[]>(
            { url },
            positions => {
                const rows: IRow[] = [];

                positions.forEach(position => {
                    const row: IRow = {
                        id: String(position.id),
                        data: position,
                        original: null,
                        selected: this.selectedPositionIds.includes(position.id),
                        changed: false,
                        invalid: false,
                        _rowVariant: null,
                        errors: {},
                        inputValues: {}
                    };
                    rows.push(row);
                });

                this.rows = rows;
                this.scheduleRowsRendering();
            },
            error => this.toast('danger', this.getTranslate('error.cannot_load_positions'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }

    private toggleRowSelection(row: IRow) {
        if (this.isEditable){
            setTimeout(() => {
                let index: number | null = null;
                for (let i = 0; i < this.selectedPositions.length; i++) {
                    const position = this.selectedPositions[i];
                    if (position.id === row.data.id) {
                        index = i;
                    }
                }

                if (index === null) {
                    if (this.multiple) {
                        this.selectedPositions.push(row.data);
                    } else {
                        this.selectedPositions = [row.data];
                    }

                    row.selected = true;
                } else {
                    this.selectedPositions.splice(index, 1);

                    row.selected = false;
                }

                this.scheduleRowsRendering();
            });
        }
    }
    // endregion
}

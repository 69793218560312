

































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import DepartmentPositionSelection from './DepartmentPositionSelection.vue';
import { Position } from '../types';
import {staffTabAccess} from "@/modules/budget/staffing-table/common";


/**
 * Должность
 */
interface IPosition extends Position {
    /**
     * ID
     */
    id: number;
}


const modelChangeEvent = 'change';


@Component({
    components: {
        DepartmentPositionSelection
    }
})
export default class DepartmentPositionCell extends Vue {
    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Object,
        required: false,
        default: null
    })
    public readonly value!: IPosition | null;

    private get valueId(): number | null {
        if (this.value === null) {
            return null;
        }
        return this.value.id;
    }

    @Prop({
        type: Number,
        required: true
    })
    public readonly versionId!: number;

    @Prop({
        type: Array,
        required: false,
        default: () => null,
    })
    public readonly filterHints!: Array<string> | null;
    // endregion

    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        if (this.value === null) {
            this.selectedPositions = [];
        } else {
            this.selectedPositions = [this.value];
        }

        this.$watch('modalVisible', () => {
            if (this.modalVisible) {
                if (this.value === null) {
                    this.selectedPositions = [];
                } else {
                    this.selectedPositions = [this.value];
                }
            }
        });
    }
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.data.*DepartmentPositionCell*.${key}`, values));
    }

    private getPositionText(position: IPosition): string {
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            return position.nameKk;
        }
        return position.nameRu;
    }
    // endregion

    // region Основное
    private selectedPositions: IPosition[] = [];

    private get selectedPosition(): IPosition | null {
        if (this.selectedPositions.length === 0) {
            return null;
        }
        return this.selectedPositions[0];
    }

    private get selectedPositionId(): number | null {
        if (this.selectedPosition === null) {
            return null;
        }
        return this.selectedPosition.id;
    }
    // endregion

    // region Модальное окно
    private modalVisible = false;

    private onModalCancelled() {
        if (this.value === null) {
            this.selectedPositions = [];
        } else {
            this.selectedPositions = [this.value];
        }
    }

    private onModalApplied() {
        if (this.selectedPositionId !== this.valueId) {
            this.$emit(modelChangeEvent, this.selectedPosition);
        }
    }
    // endregion
}
